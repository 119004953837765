import React, { useEffect, useState } from "react";
import Link from "next/link";

import PrivacyAndPolicyModal from "@/components/PrivacyAndPolicyModal";
import TermsAndConditionsModal from "@/components/TermsAndConditionsModal";

import Logo from "@/assets/logo/logo-transparent.png";
import {
  trackClickAboutUs,
  trackClickFacebookIcon,
  trackClickInstagramIcon,
  trackClickPrivacyPolicy,
  trackClickTermsOfService,
} from "@/event/mixpanel";

const SocialMediaItem = ({
  icon,
  link,
  onClick,
}: {
  icon: string;
  link: string;
  onClick: () => void;
}) => {
  return (
    <a
      className="no-underline border-circle bg-primary-300 text-primary block inline-flex justify-content-center align-items-center"
      style={{ width: "2.5rem", height: "2.5rem" }}
      href={link}
      onClick={onClick}
      target="_blank"
    >
      <i className={`pi text-2xl ${icon}`}></i>
    </a>
  );
};

const Footer = () => {
  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  return (
    <>
      <div className="bg-primary px-4 py-6 md:px-6 lg:px-6">
        <div className="flex flex-column align-items-start justify-content-between text-center lg:flex-row lg:align-items-end">
          <div>
            <ul className="text-left list-none p-0 mx-0 flex flex-column gap-3">
              <li>
                <a
                  className="text-600 cursor-pointer text-white"
                  onClick={() => {
                    setPrivacyModal(true);
                    trackClickPrivacyPolicy();
                  }}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="text-600 cursor-pointer text-white"
                  onClick={() => {
                    setTermsModal(true);
                    trackClickTermsOfService();
                  }}
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <Link
                  onClick={() => {
                    trackClickAboutUs();
                  }}
                  className="text-600 cursor-pointer no-underline text-white"
                  href="/about"
                >
                  About Us
                </Link>
              </li>
            </ul>
            <div className="text-left">
              <Link href={"/"}>
                <img src={Logo.src} alt="logo" className="w-11rem mt-7 mb-3" />
              </Link>
              <div className="text-center">
                <p className="text-sm text-600 cursor-pointer text-white">
                  AlertPass (c) 2024. All rights reserved
                </p>
              </div>
            </div>
          </div>

          <div className="flex align-items-center justify-content-center gap-3 mt-3 lg:mt-0">
            {/* <SocialMediaItem icon="pi-twitter" link="#" /> */}
            <SocialMediaItem
              icon="pi-facebook"
              link={process?.env?.socialLinks?.facebook}
              onClick={trackClickFacebookIcon}
            />
            <SocialMediaItem
              icon="pi-instagram"
              link={process?.env?.socialLinks?.instagram}
              onClick={trackClickInstagramIcon}
            />
          </div>
        </div>
      </div>

      <TermsAndConditionsModal
        onHide={() => setTermsModal(false)}
        visible={termsModal}
      />
      <PrivacyAndPolicyModal
        onHide={() => setPrivacyModal(false)}
        visible={privacyModal}
      />
    </>
  );
};

export default Footer;
